import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditNoteDialogComponent } from '@app/consignor/components/edit-note-dialog/edit-note-dialog.component';
import { ToastService } from '@app/core/services/toast.service';
import { CustomTranslateService } from '@app/core/services/translate.service';
import { ShowPdfDialogComponent } from '@app/shared/components/show-pdf-dialog/show-pdf-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
    CarrierDeliveryNoteBundle,
    ConsigneeDeliveryNoteBundle,
    DeliveryNote,
    DeliveryNoteBundle,
} from 'api/models';
import {
    DeliveryNoteBundlesService,
    DeliveryNotesService,
    FineDiscrepanciesService,
    PdfService,
} from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';

export enum OrgType {
    Consignor = 'consignor',
    Carrier = 'carrier',
    Consignee = 'consignee',
}

interface ConsigneeDeliveryNoteBundleWithNumberPlate
    extends ConsigneeDeliveryNoteBundle {
    carrierNumberPlateOutgoing?: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-delivery-notes[deliveryNoteBundleKey][organizationSiteKey]',
    templateUrl: './delivery-notes.component.html',
    styleUrls: ['./delivery-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class DeliveryNotesComponent implements OnInit {
    isAlive = true;
    isConsignee: boolean = false;
    isCarrier: boolean = false;

    @Input() orgType: OrgType = OrgType.Consignor;
    @Input() deliveryNoteBundleKey: string;
    @Input() bundleStatus: string;
    @Input() organizationSiteKey: string;
    @Input() readOnly: boolean = false;
    @Output() bundleLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() noteDeleted: EventEmitter<void> = new EventEmitter<void>();
    @ViewChildren('inputRefText') inputRefTexts: QueryList<ElementRef>;

    deliveryNoteBundle?:
        | DeliveryNoteBundle
        | ConsigneeDeliveryNoteBundleWithNumberPlate
        | CarrierDeliveryNoteBundle;
    srcFile: Blob;
    currentLanguageCode: string;

    deliveryNoteKeyForFineDiscrepancyUpload: string;
    public selectedFineDiscrepancyFiles: File[] = [];
    editStates: { [key: string]: boolean } = {};
    constructor(
        private spinner: NgxSpinnerService,
        private deliveryNotesService: DeliveryNotesService,
        private toastr: ToastService,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private customTranslateService: CustomTranslateService,
        private translateService: TranslateService,
        private deliveryNoteBundleService: DeliveryNoteBundlesService,
        private fineDiscrepanciesService: FineDiscrepanciesService,
        private pdfService: PdfService
    ) {
        this.currentLanguageCode = this.customTranslateService.currentLang;
    }

    async ngOnInit() {
        this.isConsignee = this.orgType == OrgType.Consignee;
        this.isCarrier = this.orgType == OrgType.Carrier;
        await this.getDeliveryNotes();
    }

    /**
     * Gets all delivery notes of the DLS folder.
     */
    async getDeliveryNotes() {
        await this.spinner.show();
        try {
            if (this.orgType === OrgType.Consignee) {
                const bundle = await lastValueFrom(
                    this.deliveryNoteBundleService.getConsigneeBundle({
                        deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );
                this.deliveryNoteBundle = { ...bundle };
            }

            if (this.orgType === OrgType.Consignor) {
                this.deliveryNoteBundle = await lastValueFrom(
                    this.deliveryNoteBundleService.getBundle({
                        deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );
            }

            if (this.orgType === OrgType.Carrier) {
                this.deliveryNoteBundle = await lastValueFrom(
                    this.deliveryNoteBundleService.getCarrierBundle({
                        deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );
            }

            this.bundleLoaded.emit(true);
            this.ref.detectChanges();
        } catch (error) {
        } finally {
            await this.spinner.hide();
        }
    }

    /**
     *
     * @param deliveryNoteKey
     */
    async deleteDeliveryNote(deliveryNoteKey: string) {
        await lastValueFrom(
            this.deliveryNotesService.deleteDeliveryNoteInBundle({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                deliveryNoteKey: deliveryNoteKey,
            })
        );

        this.toastr.success('Lieferschein entfernt');
        this.getDeliveryNotes();
        this.noteDeleted.emit();
    }

    editDeliveryNote(deliveryNote: DeliveryNote) {
        const dialogRef = this.dialog.open(EditNoteDialogComponent, {
            maxWidth: 600,
            disableClose: true,
            data: {
                note: deliveryNote,
                organizationSiteKey: this.organizationSiteKey,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getDeliveryNotes();
            }
        });
    }

    /**
     * @param deliveryNote
     * opens modal dialog with pdf preview
     */
    async viewDeliveryNote(deliveryNote: DeliveryNote) {
        let blobData: Blob | undefined;
        await this.spinner.show();
        try {
            if (this.orgType === OrgType.Consignee) {
                blobData = await lastValueFrom(
                    this.pdfService.getConsigneeDeliveryNoteCombinedPdf({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                /*blobData = await lastValueFrom(
                    this.pdfService.getConsigneeDeliveryNoteFromPool({
                        deliveryNoteKey: deliveryNote._key,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );*/
            }

            if (this.orgType === OrgType.Consignor) {
                blobData = await lastValueFrom(
                    this.pdfService.getConsignorDeliveryNoteCombinedPdf({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                /*blobData = await lastValueFrom(
                    this.pdfService.getDeliveryNoteFromPool({
                        deliveryNoteKey: deliveryNote._key,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );*/
            }

            if (this.orgType === OrgType.Carrier) {
                blobData = await lastValueFrom(
                    this.pdfService.getCarrierDeliveryNoteCombinedPdf({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );

                /*blobData = await lastValueFrom(
                    this.pdfService.getCarrierDeliveryNotePdf({
                        deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                        deliveryNoteKey: deliveryNote._key,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );*/
            }

            await this.spinner.hide();
            this.dialog.open(ShowPdfDialogComponent, {
                minWidth: '80%',
                height: '95%',
                disableClose: true,
                data: {
                    data: blobData,
                },
            });
        } catch (error) {
            this.translateService.instant('ERROR.DOWNLOAD-NOTE');
        }
    }

    async selectFineDiscrepancyFile(event: any) {
        const files = event.target.files;
        for (const file of files) {
            const filename: string = file.name;
            try {
                await lastValueFrom(
                    this.fineDiscrepanciesService.postNewFineDiscrepancy({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey:
                            this.deliveryNoteKeyForFineDiscrepancyUpload,
                        fileName: filename,
                        body: file,
                    })
                );
                this.selectedFineDiscrepancyFiles.push(file);
                this.toastr.success(
                    this.translateService.instant(
                        'INCOMING-GOODS.CHECKIN.FINE-DISCREPANCY-ADDED'
                    )
                );
                /* +
                   ' - ' +
                   this.translateService.instant('ATTACHMENT') +
                   ' ' +
                   `${this.selectedFineDiscrepancyFiles.length} / 30`
                */
                await this.getDeliveryNotes();
            } catch (error) {}
        }
        event.target.value = null;
    }

    checkIfAppendixFileAlreadyExists(
        deliveryNoteKey: string,
        filename: string
    ): boolean {
        let exists = false;
        if (this.selectedFineDiscrepancyFiles.length > 0) {
            exists = this.selectedFineDiscrepancyFiles.some(
                f => f.name === filename
            );
        }
        return exists;
    }

    openEditNoteReference(deliveryNote: DeliveryNote): void {
        // Initialize properties object if it doesn't exist
        if (!deliveryNote.properties) {
            deliveryNote.properties = {};
        }

        // Set edit state for this note
        this.editStates[deliveryNote._key] = true;

        setTimeout(() => {
            const inputElements = this.inputRefTexts.toArray();
            if (inputElements.length > 0) {
                // Just focus the first input element in the array
                const inputRef = inputElements[0];
                inputRef.nativeElement.focus();
                inputRef.nativeElement.select();
            }
        });
    }

    // Replace editNoteReference method
    async editNoteReference(deliveryNote: DeliveryNote): Promise<void> {
        try {
            const referenceNumber =
                deliveryNote.properties?.referenceNumber || '';

            // Validate the reference number before sending the request
            const isValid = /^$|^[^,]{3,60}$/.test(referenceNumber);

            if (!isValid) {
                this.toastr.error(
                    this.translateService.instant(
                        'OUTGOING-GOODS.UPLOAD.ERROR.NOT-MATCHING-CRITERIAS'
                    )
                );
                return;
            }

            if (this.orgType === OrgType.Carrier) {
                await lastValueFrom(
                    this.deliveryNotesService.patchCarrierDeliveryNoteProperties(
                        {
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteKey: deliveryNote._key,
                            body: { referenceNumber },
                        }
                    )
                );
            } else if (this.orgType === OrgType.Consignor) {
                await lastValueFrom(
                    this.deliveryNotesService.patchConsignorDeliveryNoteProperties(
                        {
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteKey: deliveryNote._key,
                            body: { referenceNumber },
                        }
                    )
                );
            } else if (this.orgType === OrgType.Consignee) {
            }

            if (!deliveryNote.properties) {
                deliveryNote.properties = {};
            }
            deliveryNote.properties.referenceNumber = referenceNumber;

            // Reset edit state
            this.editStates[deliveryNote._key] = false;
            this.ref.detectChanges();

            this.toastr.success(
                this.translateService.instant(
                    'OUTGOING-GOODS.FOLDER-VIEW.REFERENCE-UPDATE-SUCCESS'
                )
            );
        } catch (error) {
            this.toastr.error(this.translateService.instant('GENERAL-ERROR'));
            console.error('Error updating reference number:', error);
        }
    }

    // Replace resetEditNoteReference method
    resetEditNoteReference(deliveryNote: DeliveryNote): void {
        this.editStates[deliveryNote._key] = false;
        this.ref.detectChanges(); // Add this line
    }

    // Add a helper method to check edit state
    isNoteInEditMode(deliveryNote: DeliveryNote): boolean {
        return this.editStates[deliveryNote._key] === true;
    }
}
